import Vue from "vue";
import {Ability, AbilityBuilder} from '@casl/ability'
import router from '../../router';
import {abilitiesPlugin} from "@casl/vue"

export const ability = new Ability();

export function defineAbilitiesFor(roles) {
  const {can, rules} = new AbilityBuilder(Ability);
  router.options.routes.forEach((route) => {
    if (route.children) {
      const parentRouteRoles = route.roles;
      route.children.forEach(child => {
        const routeRoles = parentRouteRoles ? parentRouteRoles : child.roles;
        if (routeRoles && routeRoles.some(r => roles.includes(r))) {
          if(route.name){
            can('manage', route.name);
          }
          can('manage', child.name);
        }
      })
    }
  });
  roles.map(role => {
    can('manage', role);
  })
  can('manage', 'celulares-dashboard');
  can('manage', 'celulares-base-batch');
  can('manage', 'celulares-base-batch-upload');
  can('manage', 'projects');
  ability.update(rules);
}

Vue.use(abilitiesPlugin, ability);
