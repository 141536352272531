import {http} from '@/http'
import { Util } from "wize-admin";

export default {
  list(data, params) {
    return http.post('/celulares/base-batch/list', data, {params})
  },
  downloadTemplate() {
    return http.get('/celulares/base-batch/template/xlsx', {responseType: 'blob'})
  },
  uploadBatch(data){
    const formData = Util.getFormData(data);
    return http.post('/celulares/base-batch', formData, {headers: { "Content-Type": "multipart/form-data" },})
  },
  download(data) {
    return http.post('/payment-batches/xlsx', data,  {responseType: 'blob'})
  },
}
