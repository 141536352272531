import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginRoutes from '@/modules/Login/Routes'
import ProjectsRoutes from '@/modules/Projects/Routes'
import CelularesRoutes from '@/modules/Celulares/Routes'
import store from '../store'
import { ability, defineAbilitiesFor} from '@/core/plugins/abilities'

Vue.use(VueRouter);

let routes = [];
routes = routes.concat(LoginRoutes, ProjectsRoutes, CelularesRoutes);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const canNavigate = (to) => {
  return ability.can('manage', to.name) || to.meta.excludeAuth || to.path === '/404';
};

router.beforeEach(async (to, from, next) => {
  if (to.meta.excludeAuth) {
    if (!store.getters['auth/isAuthenticated']) {
      next();
    } else {
      next({name: 'projects'})
    }

  } else {
    if (store.getters['auth/isAuthenticated']) {
      if (!store.getters['auth/isTokenValid']) {
        try {
          await store.dispatch('auth/checkValidToken')
          const user = store.getters['auth/getUser']
          defineAbilitiesFor(user.roles)
          if (canNavigate(to)) {
            next();
          } else {
            next({name: "projects"});
          }
        } catch (e) {
          console.error(e)
          next({name: 'login'});
        }
      } else {
        if (canNavigate(to)) {
          next();
        } else {
          next({name: "projects"});
        }
      }
    } else {
      next({name: 'login'});
    }
  }
});

export default router
