<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: "CelularesView",
}
</script>

<style scoped lang="stylus">
</style>
