<template>
  <div>
    <div :class="`wrapper-page${isMenuOpen ? '' : '-closeSidebar'}`">
      <!-- Mobile -->
      <PageSidebarMobile :show="isMenuOpen && currentDevice != 'desktop'" />

      <!-- Desktop -->
      <page-sidebar
        :isMenuOpen="isMenuOpen"
        :tooltipType="tooltipType"
        @toggleMenu="toggleMenu"
        v-if="currentDevice == 'desktop'"
      >
        <template #custom-header>
          <img
            src="../../../../core/assets/images/layout/logo-admin.png"
            alt="Razzil"
            class="sidebar-logo"
          />
          <span class="sidebar-title w-50">MIRANA</span>
        </template>

        <template #list-items>
          <router-link
            :to="{ name: 'celulares-dashboard' }"
            tag="li"
            v-if="$can('manage', 'celulares-dashboard')"
            exact-active-class="active"
            class="d-flex sidebar-item"
            @mouseover.native="tooltipType = 'home-item'"
            @mouseleave.native="tooltipType = null"
          >
            <div class="d-flex align-items-center">
              <span class="menu-icon">
                <i class="ri-home-5-line"></i>
              </span>

              <span class="sidebar-item-text">Portada</span>

              <CustomTooltip
                custom-class="tooltip-sidebar"
                v-if="!isMenuOpen && tooltipType === 'home-item'"
                text="Portada"
              />
            </div>
          </router-link>
          <router-link
            :to="{ name: 'celulares-base-batch' }"
            tag="li"
            exact-active-class="active"
            class="d-flex sidebar-item"
            @mouseover.native="tooltipType = 'base-batch-item'"
            @mouseleave.native="tooltipType = null"
          >
            <div class="d-flex align-items-center">
              <span class="menu-icon">
                 <i class="ri-bank-card-line"></i>
              </span>

              <span class="sidebar-item-text">Carga base</span>

              <CustomTooltip
                custom-class="tooltip-sidebar"
                v-if="!isMenuOpen && tooltipType === 'base-batch-item'"
                text="Portada"
              />
            </div>
          </router-link>
        </template>
      </page-sidebar>

      <div class="wrapper-page__content">
        <page-header />
        <div class="wrapper-page__inner">
          <b-container fluid>
            <slot></slot>
          </b-container>
        </div>
      </div>

      <page-footer />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import {
  PageSidebar,
  CustomTooltip,
  PageFooter,
} from "wize-admin";

import PageHeader from "./PageHeader.vue";
import PageSidebarMobile from "./layout/PageSidebarMobile";

export default {
  name: "PageBase",
  components: {
    PageHeader,
    PageSidebar,
    PageFooter,
    CustomTooltip,
    PageSidebarMobile,
  },
  data() {
    return {
      matchMediaMd: window.matchMedia("(max-width: 768px)"),
      tooltipType: null,
    };
  },
  mounted() {
    this.calcResize();
  },
  computed: {
    ...mapGetters({
      currentSubmenu: "app/getCurrentSubmenu",
      isMenuOpen: "app/isMenuOpen",
      currentDevice: "app/getCurrentDevice",
    }),
  },
  methods: {
    ...mapMutations({
      setCurrentSubmenu: "app/setCurrentSubmenu",
      setMenu: "app/setMenu",
      toggleMenu: "app/toggleMenu",
    }),
    setCurrentDevice() {
      if (screen.width < 992) {
        this.$store.commit("app/setCurrentDevice", "mobile");
        this.toggleMenu(false);
      } else {
        this.$store.commit("app/setCurrentDevice", "desktop");
        this.toggleMenu(true);
      }
    },
    calcResize() {
      this.setCurrentDevice();

      this.lastWidth = window.innerWidth;

      window.addEventListener("resize", () => {
        const currentWidth = window.innerWidth;

        if (currentWidth !== this.lastWidth) {
          this.setCurrentDevice();
          this.lastWidth = currentWidth;
        }
      });
    },
    isMatchMedia() {
      if (this.matchMediaMd.matches) {
        this.setMenu(false);
      } else {
        this.setMenu(true);
      }
    },
    isCurrent(submenu) {
      return this.currentSubmenu.includes(submenu);
    },
  },
  created() {
    this.matchMediaMd.addListener(this.isMatchMedia);
  },
};
</script>

<style scoped lang="stylus"></style>
