import ProjectsView from "../ProjectsView.vue";
import ProjectsPage from "../Pages/ProjectsPage.vue";

const routes = [
  {
    path: '/proyectos',
    component: ProjectsView,
    children: [
      {
        path: '',
        name: 'projects',
        component: ProjectsPage,
      },
    ]
  }
]

export default routes
