<template>
  <b-button
      class="
      projects-card
      d-block
      animate__animated animate__fadeIn
    "
      @click="selectBusiness"
  >
    <div class="h-100 d-flex flex-column justify-content-between">
      <section class="text-left">
        <span class="title text-dark">{{ project.name }}</span>
      </section>

      <div class="w-100 d-flex justify-content-between align-items-end">
        <div class="text-left">

        </div>

        <BusinessIcon class="fill-primary" />
      </div>
    </div>
  </b-button>
</template>

<script>
import { BusinessIcon } from 'wize-admin'

export default {
  name: "ProjectCard",

  data() {
    return {};
  },
  components: {
    BusinessIcon
  },
  methods: {
    async selectBusiness(){
      // TODO Put loading here
      this.$router.push({name: this.project.route})
    }
  },
  props: {
    project: Object,
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/card';
</style>
