import CelularesView from "../CelularesView.vue";
import DashboardRoutes from "../Dashboard/Routes";
import BaseBatchRoutes from "../BaseBatch/Routes";

let children = [];

children = children.concat(DashboardRoutes, BaseBatchRoutes);

const routes = [
  {
    path: '/celulares-ad',
    component: CelularesView,
    children: children
  }
]

export default routes
