<template>
  <div class="projects-list container">
    <div class="row justify-content-center">
      <div class="col-11 col-lg-9">
        <div class="pb-5 pb-md-0">
          <div class="container">
            <div class="row">
              <div class="col px-0">
                <h3 class="title text-white mb-4">Todos los proyectos</h3>
              </div>
            </div>
            <div class="row">
              <!-- TODO hiding add business button for now -->
              <!--  <div class="col-md-4 px-md-2">
                <b-button
                  class="
                    card-add
                    d-block
                    border-0
                    animate__animated animate__fadeIn
                    bg-white
                  "
                >
                  <div
                    class="
                      h-100
                      d-flex
                      justify-content-center
                      align-items-center
                      text-center
                    "
                  >
                    <div class="add-img">
                      <img
                        src="@/core/assets/images/projects/plus.svg"
                        alt="Icono para agregar empresa"
                        class="d-inline-block"
                      />

                      <h3 class="card-text">
                        Agregar marca comercial
                      </h3>
                    </div>
                  </div>
                </b-button>
              </div>-->
              <!-- Card empresa -->
              <div
                class="col-md-4 px-md-0"
                v-for="(project, key) in projects"
                :key="key"
              >
                <Card :project="project" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "./Card";

export default {
  name: "ProjectsList",

  data() {
    return {
      projects: [
        {
          name: "Celulares AD",
          route: "celulares-dashboard",
        },
      ]
    };
  },
  components: {
    Card,
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/projects-list';
</style>
