import BaseBatchView from "../BaseBatchView.vue";
import BaseBatchList from "../Pages/BaseBatchList.vue";
import BaseBatchUpload from "../Pages/BaseBatchUpload.vue";

const routes = [
  {
    path: 'carga-base',
    component: BaseBatchView,
    children: [
      {
        path: '',
        name: 'celulares-base-batch',
        component: BaseBatchList
      },
      {
        path: 'carga',
        name: 'celulares-base-batch-upload',
        component: BaseBatchUpload
      },
    ]
  },
]

export default routes
