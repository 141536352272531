const Errors = {
  SERVER_ERROR: "Ocurrió un error en la petición, por favor intenta nuevamente.",
}

const PaymentStatus = {
  PENDING: {key: 1, label: 'PENDIENTE', color: 'badge-gray'},
  REVIEW: {key: 2, label: 'EN REVISIÓN', color: 'badge-yellow'},
  AUTHORIZED: {key: 3, label: 'AUTORIZADO', color: 'badge-yellow'},
  APPROVED: {key: 4, label: 'APROBADO', color: 'badge-green'},
  REJECTED: {key: 5, label: 'RECHAZADO', color: 'badge-red'},
  PAID: {key: 6, label: 'PAGADO', color: 'badge-purple'},
  INCORRECT: {key: 7, label: 'PAGO INCORRECTO', color: 'badge-red'},
  CANCELED: {key: 8, label: 'ANULADO', color: 'badge-red'},
  ABONO_CORRECTO: {key: 'ABONO CORRECTO', label: 'ABONO CORRECTO', color: 'badge-green'},
  ABONO_ENVIADO: {key: 'ABONO ENVIADO', label: 'ABONO ENVIADO', color: 'badge-green'},
  CUENTA_INEXISTENTE: {key: 'CUENTA INEXISTENTE', label: 'CUENTA INEXISTENTE', color: 'badge-red'},
  DOCUMENTO_ERRADO: {key: 'DOCUMENTO ERRADO', label: 'DOCUMENTO ERRADO', color: 'badge-red'},
  DOC_NO_CORRESPONDE: {key: 'DOCUMENTO NO CORRESPONDE', label: 'DOCUMENTO NO CORRESPONDE', color: 'badge-red'}
}

const VoucherStatus = {
  REVIEW: {key: 1, label: 'EN REVISIÓN', color: 'badge-gray'},
  AUTHORIZED: {key: 2, label: 'AUTORIZADO', color: 'badge-yellow'},
  APPROVED: {key: 3, label: 'APROBADO', color: 'badge-green'},
  REJECTED: {key: 4, label: 'RECHAZADO', color: 'badge-red'},
}


const PushersStatus = {
  ACTIVE: {key: 1, label: 'ACTIVO', color: 'badge-green'},
  INACTIVE: {key: 2, label: 'SUSPENDIDO', color: 'badge-yellow'}
}

const ExpenseLineStatus = {
  ACTIVE: {key: 1, label: 'ACTIVO', color: 'badge-green'},
  INACTIVE: {key: 2, label: 'SUSPENDIDO', color: 'badge-yellow'}
}

const AuditLogAction = {
  CREATE: {key: 'CREATE', label: 'CREAR', color: 'badge-gray'},
  UPDATE: {key: 'UPDATE', label: 'ACTUALIZAR', color: 'badge-yellow'},
  DELETE: {key: 'DELETE', label: 'ELIMINAR', color: 'badge-red'},
  LOGIN: {key: 'LOGIN', label: 'INICIO DE SESIÓN', color: 'badge-green'},
}

const SupplierStatus = {
  ACTIVE: {key: 1, label: 'ACTIVO', color: 'badge-green'},
  INACTIVE: {key: 2, label: 'SUSPENDIDO', color: 'badge-yellow'},
  LOCKED: {key: 3, label: 'BLOQUEADO', color: 'badge-red'},
}

const PaymentMadeBatchStatus = {
  PROCESSING: {key: 1, label: 'EN PROCESO', color: 'badge-gray'},
  SUCCESS: {key: 2, label: 'PROCESADO', color: 'badge-green'},
  HAS_ERRORS: {key: 3, label: 'PROCESADO CON ERRORES', color: 'badge-yellow'},
  ERROR: {key: 4, label: 'ERROR AL PROCESAR', color: 'badge-red'},
}

const PaymentMadeRecordStatus = {
  SUCCESS: {key: 1, label: 'PAGADO', color: 'badge-green'},
  ERROR: {key: 2, label: 'PAGO CON ERRORES', color: 'badge-yellow'},
  NOT_FOUND: {key: 3, label: 'NO CONSIGNA PAGO', color: 'badge-red'},
  CONFLICTS: {key: 4, label: 'CON OBSERVACIONES', color: 'badge-red'},
}


const RejectReasonStatus = {
  ACTIVE: {key: 1, label: 'ACTIVO', color: 'badge-green'},
  INACTIVE: {key: 2, label: 'SUSPENDIDO', color: 'badge-yellow'},
}

const ProjectTypeStatus = {
  ACTIVE: {key: 1, label: 'ACTIVO', color: 'badge-green'},
  INACTIVE: {key: 2, label: 'SUSPENDIDO', color: 'badge-yellow'},
}

const ZonalStatus = {
  ACTIVE: {key: 1, label: 'ACTIVO', color: 'badge-green'},
  INACTIVE: {key: 2, label: 'SUSPENDIDO', color: 'badge-yellow'},
}

const PaymentFrequency = {
  WEEKLY: {key: 1, label: 'SEMANAL', periods: 4},
  BIWEEKLY: {key: 2, label: 'QUINCENAL', periods: 2},
  MONTHLY: {key: 3, label: 'MENSUAL', periods: 1},
}

const ManagementType = {
  ON_THE_GROUP: {key: 1, label: 'EN EL GRUPO'},
  INDIVIDUAL: {key: 2, label: 'PROPIA'},
}

const SupplierType = {
  REGULAR: {key: 1, label: 'REGULAR'},
  SPECIAL: {key: 2, label: 'ESPECIAL'},
}

const BooleanTypes = {
  YES: {key: 1, label: 'SÍ'},
  NO: {key: 0, label: 'NO'},
}

const IdentifierType = {
  DNI: {key: 1, label: 'DNI'},
  CE: {key: 4, label: 'CARNE DE EXTRANJERIA'},
  RUC: {key: 6, label: 'RUC'},
  PASAPORTE: {key: 7, label: 'PASAPORTE'},
  CARNE_REFUGIO: {key: 9, label: 'CARNE DE SOLICITUD DE REFUGIO'},
  CARNE_IDENTIDAD: {key: 22, label: 'CARNE DE IDENTIDAD – RR.EE.'},
  CARNE_PERMANENCIA: {key: 23, label: 'CARNE PERMISO TEMP. PERMANENCIA'},
  DNI_EXTRANJERO: {key: 24, label: 'DOC. DE IDENTIDAD EXTRANJERO'},
  LIBRETA_MILITAR: {key: 90, label: 'LIBRETA MILITAR'},
  CARNET_MILITAR: {key: 91, label: 'CARNET MILITAR'},
  OTROS: {key: 0, label: 'OTROS'},
}

const AccountType = {
  SAVINGS: {key: 1, label: 'AHORROS'},
  CURRENT: {key: 2, label: 'CORRIENTE'},
  SALARY: {key: 3, label: 'SUELDO'},
}

const PaymentBatchStatus = {
  PENDING: {key: 1, label: 'PENDIENTE', color: 'badge-gray'},
  APPROVED: {key: 2, label: 'APROBADO', color: 'badge-green'},
}

const VoucherTypes = {
  eInvoice: {key: 'eInvoice', label: 'Factura electrónica', shortLabel: 'FT', value: 1},
  creditNote: {key: 'creditNote', label: 'Nota de crédito', shortLabel: 'NC', value: 2},
  eReceipt: {key: 'eReceipt', label: 'Boleta electrónica', shortLabel: 'BVE', value: 3},
  eReceiptForFees: {key: 'eReceiptForFees', label: 'Recibo por honorarios electrónicos', shortLabel: 'RXH', value: 4},
  manualReceipt: {key: 'manualReceipt', label: 'Boleta manual', shortLabel: 'BVM', value: 5},
}

const PaymentType = {
  CREDIT: {key: 'Credito', label: 'Credito'},
  CASH: {key: "Contado", label: 'Contado'},
}

export default {
  Errors,
  PaymentStatus,
  PushersStatus,
  SupplierStatus,
  ManagementType,
  RejectReasonStatus,
  BooleanTypes,
  ProjectTypeStatus,
  ZonalStatus,
  PaymentFrequency,
  IdentifierType,
  ExpenseLineStatus,
  SupplierType,
  AccountType,
  PaymentBatchStatus,
  VoucherTypes,
  AuditLogAction,
  PaymentType,
  PaymentMadeBatchStatus,
  PaymentMadeRecordStatus,
  VoucherStatus
}
