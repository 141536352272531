<template>
  <section class="projects-page d-flex flex-column justify-content-between">
    <div>
      <Header/>

      <Banner/>

      <List/>
    </div>

    <PageFooter/>
  </section>
</template>

<script>
import Header from '../Components/Header'
import Banner from '../Components/Banner'
import List from '../Components/List'
import { PageFooter } from 'wize-admin'

export default {
  components: {
    Header,
    Banner,
    List,
    PageFooter
  }
}
</script>

<style lang="stylus" scoped>
@import "../Styles/projects-page.styl"
</style>
