<template>
  <div id="app">
    <Loader v-if="isLoading"/>
    <router-view/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import {Loader} from 'wize-admin'

export default {
  mounted() {
    document.documentElement.style.setProperty('--color-primary', '#448AFF')
    document.documentElement.style.setProperty('--color-primary-dark', '#2979FF')
  },
  components: {
    Loader
  },
  computed: {
    ...mapGetters({
      isLoading: 'app/isLoading'
    })
  }
}
</script>

<style>
</style>
