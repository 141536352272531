<template>
  <aside class="page-sidebar-mobile-container">
    <div
      class="page-sidebar-mobile-overlay"
      :class="{ active: show }"
      @click="toggleMenu(false)"
    ></div>

    <div
      class="page-sidebar-mobile d-flex flex-column d-lg-none"
      :class="{ active: show }"
    >
      <div class="header d-flex align-items-center">
        <b-avatar
          class="avatar"
          src="https://assets.admin.wize.pe/images/dropdown-user-avatar.svg"
        >
        </b-avatar>
        <div class="d-flex flex-column pl-3">
          <span class="user-name">{{ username }}</span>
          <span class="email d-inline-block mt-1">{{ user.email }}</span>
        </div>
      </div>

      <div class="grid mt-2 px-2">
        <router-link
          :to="{ name: 'celulares-dashboard' }"
          class="link d-flex flex-column justify-content-center align-items-center text-white text-center"
          :class="{ active: $route.name === 'celulares-dashboard' }"
        >
          <span class="menu-icon">
            <i class="ri-home-5-line"></i>
          </span>

          Portada
        </router-link>

        <router-link
          :to="{ name: 'celulares-base-batch' }"
          class="link d-flex flex-column justify-content-center align-items-center text-white text-center"
          :class="{ active: $route.name === 'celulares-base-batch' }"
        >
          <span class="menu-icon">
            <i class="ri-bank-card-line"></i>
          </span>

          Carga base
        </router-link>
      </div>

      <div class="menu-list-container">
        <ul class="menu-list list-unstyled" v-if="selection">
          <li v-for="item in getMenuItems" :key="item.text">
            <router-link
              :to="item.route"
              exact-active-class="active"
              class="menu-subitem text-decoration-none"
              ><BulletIcon class="fill-white mr-3" />{{
                item.text
              }}</router-link
            >
          </li>
        </ul>
      </div>

      <button
        type="button"
        class="menu-option d-flex align-items-center"
        @click="logout()"
      >
        <LogoutIcon class="fill-white mr-4" />

        <span class="sidebar-item-text">Cerrar sesión</span>
      </button>

      <div
        class="menu-option classic version d-flex justify-content-between align-items-center"
      >
        <span class="text">V{{ version }} {{ year }}</span>
        <p class="my-0">
          Forjado por <a href="https://wize.pe" target="_blank">Wize</a>
        </p>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { Constants as LocalConstants } from "@/core/utils";
import { BulletIcon, LogoutIcon, Helpers } from "wize-admin";

export default {
  name: "PageSidebarMobile",
  data() {
    return {
      LocalConstants,
      version: process.env.PACKAGE_VERSION,
      year: new Date().getFullYear(),
      selection: "",
      menuList: [
        {
          key: "home",
        },
        {
          key: "celulares-base-batch",
        },
      ],
    };
  },
  mounted() {
    this.setSelection(this.$route.name);

  },
  props: {
    show: Boolean,
  },
  components: {
    BulletIcon,
    LogoutIcon,
  },
  watch: {
    "$route.name": function () {
      if (this.currentDevice != "desktop") {
        this.toggleMenu(false);
      }
    },
  },
  methods: {
    ...mapMutations({
      toggleMenu: "app/toggleMenu",
    }),
    setSelection(key) {
      this.selection = key;
    },
    logout() {
      this.$store
        .dispatch("auth/logout")
        .then(() => this.$router.push({ name: "login" }));
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      isMenuOpen: "app/isMenuOpen",
      currentDevice: "app/getCurrentDevice",
    }),
    username: function () {
      return Helpers.capitalizeName(this.user.name);
    },
    getMenuItems() {
      let item = this.menuList.find((item) => item.key === this.selection);
      return item ? item.list : [];
    },
    isBaseBatch() {
      let routes = ["celulares-base-batch"];
      return (
        routes.some((str) => this.$route.name.includes(str)) ||
        routes.some((str) => this.selection.includes(str))
      );
    },
    isConfigRoute() {
      let routes = ["config"];
      return (
        routes.some((str) => this.$route.name.includes(str)) ||
        routes.some((str) => this.selection.includes(str))
      );
    },
  },
};
</script>

<style lang="stylus">
@import "../../Styles/components/layout/page-sidebar-mobile.styl"
</style>
